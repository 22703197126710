<template>
  <v-container fill-height fluid>
    <UserCardListSkeleton v-if="loading" />
    <v-row v-if="!loading" dense align="center" justify="center">
      <v-col v-for="user in users" :key="user.user_id" cols="auto">
        <v-card :to="'/roster/' + user.user_id" class="mx-auto" :color="getColor(user.roster.badCY)" dark max-width="400">
          <v-card-title>
            <v-list-item align="center" justify="center">
              <v-list-item-content>
                <v-list-item-title v-if="user.metadata.team_name">{{user.metadata.team_name}}</v-list-item-title>
                <v-list-item-title v-else>N/A</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="grow">
              <v-list-item-content>
                <v-list-item-title>Contract Years
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#696969" dark dense x-small v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <span max->
                      <v-list-item class="grow">
                        <v-list-item-content>
                          <v-list-item-subtitle class="wrap-text">Based on rostered player
                            nicknames.</v-list-item-subtitle>
                          <v-list-item-subtitle class="wrap-text">Equation: Sum(Players Contract Years) - IR + Dead
                            Years</v-list-item-subtitle>
                          <v-list-item-subtitle class="wrap-text">Math: {{ user.roster.contract.years }} - {{ user.roster.contract.ir_credit }} + {{user.roster.contract.dead_years}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </span>
                  </v-tooltip>
                  : {{ user.roster.contract.total }} / 50
                </v-list-item-title>
                <v-list-item-title>FAAB: ${{ user.roster.faab }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="grow">
              <v-list-item-content>
                <v-list-item-title>Starters: {{ user.roster.starters }}</v-list-item-title>
                <v-list-item-title>Bench: {{ user.roster.bench }}</v-list-item-title>
                <v-list-item-title>Taxi: {{ user.roster.taxi }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Base CY: {{ user.roster.contract.years }}</v-list-item-title>
                <v-list-item-title>IR Credit: {{ user.roster.contract.ir_credit }}</v-list-item-title>
                <v-list-item-title>DY:
                  {{ user.roster.contract.dead_years }}</v-list-item-title>
                <v-list-item-title>Missing:
                  <div style="display:inline;" v-if="user.roster.contract.missing_years == 0">{{ user.roster.contract.missing_years }}</div>
                  <v-chip v-if="user.roster.contract.missing_years > 0" color="red" dark>
                    {{ user.roster.contract.missing_years }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Open: {{ user.roster.open }}</v-list-item-title>
                <v-list-item-title>Total: {{ user.roster.players }}</v-list-item-title>
                <v-list-item-title>IR: {{ user.roster.ir }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>

          <v-card-actions>
            <ListItemAvatar :owner="user" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rfaMixin from '../../mixins/rfaMixin.js'
import { mapState, mapActions, mapGetters } from 'vuex';
import UserCardListSkeleton from './skeletons/UserCardList';
import ListItemAvatar from './list-items/Avatar';
import _replace from 'lodash/replace';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import _parseInt from 'lodash/parseInt';
import _isEmpty from 'lodash/isEmpty';

export default {
  name: 'UserCardList',
  mixins: [rfaMixin],
  async mounted () {
    try {
      this.loading = true;
      await this.getLeagueUsers();
    } finally {
      this.loading = false;
    }
  },
  components: {
    UserCardListSkeleton,
    ListItemAvatar
  },
  data: function () {
    return {
      users: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters('league', ['getDropTransactions']),
    ...mapState('league', ['owners', 'rosters', 'yearRosters', 'seasons', 'season']),
  },
  methods: {
    ...mapActions('league', [
      'setPastWeeksRosterTransactions',
      'setCurrentWeeksRosterTransactions',
      'setOwners',
      'setRosters',
    ]),
    findPreviousSeasonLeagueId() {
      let prevYear = _parseInt(this.season.year) - 1;
      let result = _find(this.seasons, {'year': prevYear.toString()});
      return result.leagueId;
    },
    async setStateAsync () {
      let setOwnersTask = this.setOwners();
      let setRostersTask = this.setRosters();
      let setOwnerYearRoster = this.setOwnerYearRosterStateAsync(this.findPreviousSeasonLeagueId());
      await setOwnersTask;
      await setRostersTask;
      await setOwnerYearRoster;
    },
    async setTransactionsAsync (currentWeek) {
      let setPastWeeksRosterTransactionsTask = this.setPastWeeksRosterTransactions(
        currentWeek
      );
      let setCurrentWeeksRosterTransactionsTask = this.setCurrentWeeksRosterTransactions(
        currentWeek
      );
      await setPastWeeksRosterTransactionsTask;
      await setCurrentWeeksRosterTransactionsTask;
    },
    async getLeagueUsers () {
      let me = this;
      await me.setStateAsync();
      let users = me.owners;
      let rosters = me.rosters;
      let yearRosters = me.yearRosters;
      let currenWeek = me.getCurrentWeek();
      await me.setTransactionsAsync(currenWeek);
      let dropRosters = me.getDropTransactions;
      
      users.forEach((user) => {
        let rosterIndex = rosters.findIndex((item) => item.owner_id == user.user_id);
        let roster = rosters[rosterIndex];
        let yearRosterIndex = rosters.findIndex((item) => item.owner_id == user.user_id);
        let yearRoster = yearRosters[yearRosterIndex];
        user['roster'] = {
          starters: me.getNullableCount(roster.starters),
          taxi: me.getNullableCount(roster.taxi),
          ir: me.getNullableCount(roster.reserve),
          players: me.getNullableCount(roster.players),
          faab: 1000 - roster.settings.waiver_budget_used,
        };
        user.roster['badCY'] = me.checkContractYearsUpdate(roster, yearRoster);
        user.roster['open'] = 25 - (user.roster.players - user.roster.ir);
        user.roster['bench'] =
          user.roster.players -
          (user.roster.starters + user.roster.taxi + user.roster.ir);
        user.roster['contract'] = {
          years: me.calculateContractYears(roster.players, roster.metadata),
          missing_years: me.calculateMissingContractYears(roster.players, roster.metadata),
          ir_credit: me.calculateInjuryReserveCredit(
            roster.reserve,
            roster.metadata
          ),
          dead_years: me.calculateDeadContractYears(
            dropRosters[roster.roster_id],
            roster.metadata
          ),
        };

        if (user.user_id == '366677050985152512' && user.league_id == '652329680191430656')
          user.roster.contract.dead_years -= 2;

        user.roster.contract['total'] =
          user.roster.contract.years -
          user.roster.contract.ir_credit +
          user.roster.contract.dead_years;

        user['avatar_thumb'] = 'https://sleepercdn.com/avatars/thumbs/' + user.avatar;
      });
      this.users = _orderBy(
        users,
        [(user) => _lowerCase(user.display_name)],
        ['asc']
      );
    },
    calculateInjuryReserveCredit (players, metadata) {
      let count = 0;
      if (!players) return count;

      players.forEach((playerId) => {
        let key = 'p_nick_' + playerId;
        let nickName = metadata[key];
        if (nickName && _includes(_lowerCase(nickName), 'cy', 0)) {
          let contractYear = this.getNumberFromString(nickName);
          if (contractYear > 1) count += contractYear - 1;
        }
      });

      return count;
    },
    calculateDeadContractYears (players, metadata) {
      let count = 0;
      if (!players) return count;

      players.forEach((playerId) => {
        let key = 'p_nick_' + playerId;
        let nickName = metadata[key];
        if (nickName && !_includes(_lowerCase(nickName), 'taxi', 0)) {
          if (this.getNumberFromString(nickName) > 0) {
            count++;
          }
        }
      });

      return count;
    },
    calculateContractYears (players, metadata) {
      let count = 0;
      if (!players) return count;

      players.forEach((playerId) => {
        let key = 'p_nick_' + playerId;
        let nickName = metadata[key];
        if (nickName && _includes(_lowerCase(nickName), 'cy', 0))
          count += this.getNumberFromString(nickName);
      });

      return count;
    },
    calculateMissingContractYears (players, metadata) {
      let count = 0;
      if (!players) return count;

      players.forEach((playerId) => {
        let key = 'p_nick_' + playerId;
        let nickName = metadata[key];
        if (_includes(_lowerCase(nickName), 'cy', 0) || _includes(_lowerCase(nickName), 'taxi', 0) || _includes(_lowerCase(nickName), 'rfa', 0))
          return;
        count += 1;
      });

      return count;
    },
    checkContractYearsUpdate (currentUserRoster, currentUserYearRoster){
      let results = [];
      currentUserRoster.players.forEach((currentPlayer) => {
        let currentPlayerNN = currentUserRoster.metadata['p_nick_' + currentPlayer];
        let yearPlayerNN = currentUserYearRoster.metadata['p_nick_' + currentPlayer];
        
        if(!_includes(_lowerCase(currentPlayerNN), 'taxi', 0) && yearPlayerNN){
          if((_includes(_lowerCase(yearPlayerNN), 'taxi', 0) && _includes(_lowerCase(currentPlayerNN), 'cy', 0)) || (_lowerCase(yearPlayerNN) === '1 cy' && (_lowerCase(currentPlayerNN) === 'rfa' || _includes(_lowerCase(currentPlayerNN), 'cy', 0))))
            return;
            if ((!_includes(_lowerCase(currentPlayerNN), 'cy', 0) && !_includes(_lowerCase(yearPlayerNN), 'cy', 0)) || (_lowerCase(yearPlayerNN) === '1 cy' && (_lowerCase(currentPlayerNN) !== 'rfa' || !_includes(_lowerCase(currentPlayerNN), 'cy', 0)))) {
              results.push(currentPlayer);
          }
          else {
            let currentPlayerCY = this.getNumberFromString(currentPlayerNN);
            let yearPlayerCY = this.getNumberFromString(yearPlayerNN) - 1;
            if(currentPlayerCY !== yearPlayerCY)
              results.push(currentPlayer);
          }
        }
      });
      return results;
    },
    getCurrentWeek () {
      return 17; //TODO: Fix this for 2021 season
      // let weeksBetween = function (d1, d2) {
      //   return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
      // };
      // const today = new Date();
      // const startingWeek = new Date(today.getFullYear(), 8, 6);
      // let currentWeek = weeksBetween(startingWeek, today);
      // return currentWeek;
    },
    getNullableCount (items) {
      return items ? items.length : 0;
    },
    getNumberFromString (item) {
      let itemNumber = _replace(item, /\D/g, '');
      let result = 0;
      try {
        if (itemNumber) result = parseInt(itemNumber[0]);
      } catch {
        //eat
      }

      return result;
    },
    getColor(items){
      return _isEmpty(items) ? '#26c6da' : '#FF0000';
    }
  },
};
</script>

<style>
span {
  display: block;
  max-width: 200px;
  word-wrap: break-word;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>
