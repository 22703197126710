<template>
  <v-container fill fluid>
    <v-card
      max-width="200px"
      min-width="122px"
      elevation=0
    >
    <v-card-text>
      <div class="mx-auto text-center">
        <v-avatar
          color="grey darken-3"
          :size="owner.avatar_size ? owner.avatar_size : 40"
        >
          <v-img  alt="" :src="owner.avatar_thumb" />
        </v-avatar>
        <p class="mt-1" style="white-space: nowrap">
          {{ owner.display_name }}
        </p>
        <span class="text-caption tex-left mt-1">
          FAAB: ${{ owner.roster.faab }}
        </span>
        <span class="text-caption tex-left mt-1">
          Spent: $0
        </span>
        <span class="text-caption tex-left mt-1">
          New: ${{ total }}
        </span>
      </div>
    </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Owner',
  props: {
    owner: {}
  },
  computed: {
    total: function () {
      return this.owner.roster.faab - 0; //TODO: Set spent value instead of 0
    }
  },
};
</script>

<style></style>
