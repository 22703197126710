import { mapState, mapActions, mapGetters } from 'vuex';
import _replace from 'lodash/replace';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import _chunk from 'lodash/chunk';

const rfaMixin = {
      data() {
        return {
          rfaPlayers: [],
        };
      },
      computed: {
        ...mapGetters('league', ['getPlayer']),
        ...mapState('league', ['owners', 'yearRosters']),
      },
      methods: {
        ...mapActions('league', ['setPlayerAsync', 'setOwners', 'setYearRosters']),
        async setOwnerYearRosterStateAsync (leagueid) {
          let setOwnersTask = this.setOwners();
          let setYearRostersTask = this.setYearRosters(leagueid);
          await setOwnersTask;
          await setYearRostersTask;
        },
        async getPlayerAsync (playerId) {
          let player = this.getPlayer(playerId);
          if (!player) {
            await this.setPlayerAsync(playerId);
            player = this.getPlayer(playerId);
          }
    
          return player;
        },
        async getRfaPlayersAsync () {
          let me = this;
          let owners = me.owners;
          let yearRosters = me.yearRosters;
          let results = [];
    
          for (let owner of owners) {
            let index = yearRosters.findIndex((item) => item.owner_id == owner.user_id);
            if(index === -1){
              if(owner.user_id === "605901576980221952"){ //Tony user Id
                index = yearRosters.findIndex((item) => item.owner_id == "459770157951086592"); //Hack for Lee Leaving
              }
            }

            let yearRoster = yearRosters[index];
    
            let playerIdChunks = _chunk(yearRoster.players, 20);
            for (let playerIdChunk of playerIdChunks) {
              let playersTasks = [];
              for (let playerId of playerIdChunk) {
                let key = 'p_nick_' + playerId;
                let nickName = yearRoster.metadata[key];
                if (nickName && _includes(_lowerCase(nickName), 'cy', 0)) {
                  let itemNumber = _replace(nickName, /\D/g, '');
    
                  try {
                    if (itemNumber && parseInt(itemNumber[0]) - 1 == 0) {
                      playersTasks.push(
                        me.getPlayerAsync(
                          '/integration/sleeper/nfl/players/' + playerId
                        )
                      );
                    }
                  } catch {
                    //eat
                  }
                }
              }
              let players = await Promise.all(playersTasks);
              players.forEach((player) => {
                player['owner_name'] = owner.display_name;
                player['owner_team_name'] = owner.metadata.team_name ?? 'N/A';
                results.push(player);
              });
            }
          }
          this.rfaPlayers = results;
        },
    },
};

export default rfaMixin;