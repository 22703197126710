import sleeperService from "@/services/sleeper";
import { createRequestForChange, updateRequestForChange, deleteRequestForChange, createWheelOfLast, updateWheelOfLast, updateDues, deleteWheelOfLast } from "@/graphql/mutations";
import { listRequestForChanges, listWheelOfLastsByLeague, listDuesByLeague, getIntegrationSleeper } from "@/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _toString from "lodash/toString";
import _flatten from "lodash/flatten";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
import _remove from 'lodash/remove';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';

// initial state
const state = () => ({
  requestForChanges: [],
  wheelOfLasts: [],
  dues: [],
  players: [],
  owners: [],
  rosters: [],
  yearRosters: [],
  details: {},
  draft: {},
  pastWeeklyRosterTransactions: {},
  currentWeekRosterTransactions: [],
  season: {},
  seasons: [
    { year: '2024', leagueId: '1054445771782746112' },
    { year: '2023', leagueId: '920084256288808960' },
    { year: '2022', leagueId: '785744564068745216' },
    { year: '2021', leagueId: '652329680191430656' },
    { year: '2020', leagueId: '516669071898734592' },
    { year: '2019', leagueId: '458872026925363200' }
  ],
  matchups: []
});

// getters
const getters = {
    getPlayer: (state) => (playerId) => {
        const findPlayer = function(item) {
            return item.id === playerId;
        };
        let player = _find(state.players, findPlayer, 0);
        return player;
    },
    getDropTransactions: (state) => {
      let drops = {};
      let setDrops = function(transactions, drops){
        transactions.forEach((transaction) => {
          if (
            (transaction.type == "waiver" ||
              transaction.type == "free_agent") &&
            transaction.drops &&
            transaction.status == "complete"
          ) {
            if (transaction.drops) {
              let dropPlayers = Object.keys(transaction.drops);
              let dropRosters = Object.values(transaction.drops);
              for (let j = 0; j < dropPlayers.length; j++) {
                let dropRoster = _toString(dropRosters[j]);
                if (dropRoster in drops)
                  drops[dropRoster].push(dropPlayers[j]);
                else drops[dropRoster] = [dropPlayers[j]];
              }
            }
          }
        });
      };
      
      setDrops(_flatten(Object.values(state.pastWeeklyRosterTransactions)), drops);
      setDrops(state.currentWeekRosterTransactions, drops);
      
      return drops;
    }
};

// actions
const actions = {
    async setPlayerAsync ({ commit }, playerId) {
        let player = (await API.graphql(
            graphqlOperation(getIntegrationSleeper, {
              id: playerId,
            })
          )
        ).data.getIntegrationSleeper;
        player["full_name"] = player.first_name + " " + player.last_name;
        commit('setPlayer', player);
    },
    async setLeagueMatchups ({commit, state}, totalWeeks) {
      if(_isEmpty(state.matchups)) {
        let allMatchups = [];
        for(let i = 1; i <= totalWeeks; i++ ){
          let matchups = [];
          let weekMatchups = (await sleeperService.leagueMatchupsAsync(state.season.leagueId, i)).data;
          if(weekMatchups)
            var groupRosters = _groupBy(weekMatchups, matchup => matchup.matchup_id);
            _forEach(groupRosters, rosters => {
              matchups.push({id: rosters[0].matchup_id, teamOne:{roster_id:rosters[0].roster_id, points:rosters[0].points}, teamTwo:{roster_id:rosters[1].roster_id, points:rosters[1].points}});
            });
            allMatchups.push({week: i, matchups: matchups});
        }
        
        commit('setLeagueMatchups', allMatchups);     
        }
    },
    async setPastWeeksRosterTransactions ({commit, state}, currentWeek) {
      for (let i = 1; i < currentWeek; i++) {
        if(_isEmpty(state.pastWeeklyRosterTransactions[i])) {
          let transactions = (await sleeperService.rosterTransactionsAsync(state.season.leagueId, i)).data;
          commit('setPastWeeklyRosterTransactions', {week: i, transactions: transactions ? transactions : []});   
        }  
      }
    },
    async setCurrentWeeksRosterTransactions ({commit, state}, currentWeek) {
      if(_isEmpty(state.currentWeekRosterTransactions)) {
        let transactions = (await sleeperService.rosterTransactionsAsync(state.season.leagueId, currentWeek)).data;
        if (transactions)
          commit('setCurrentWeeksRosterTransactions', transactions);     
        }
    },
    async setOwners({commit, state}) {
      if(_isEmpty(state.owners)){
        let owners = (await sleeperService.leagueUsersAsync(state.season.leagueId)).data;
        commit('setOwners', owners);     
      }
    },
    async setDraft({commit, state}) {
      if(_isEmpty(state.draft)){
        let draft = (await sleeperService.leagueDraftAsync(state.season.leagueId)).data;
        commit('setDraft', _isEmpty(draft) ? {} : draft[0]);     
      }
    },
    async setRosters({commit, state}) {
      if(_isEmpty(state.rosters)){
        let rosters = (await sleeperService.leagueRostersAsync(state.season.leagueId)).data;
        commit('setRosters', rosters);     
      }
    },
    async setYearRosters({commit, state}, leagueid) {
      //if(_isEmpty(state.yearRosters)){
        let yearRosters = (await sleeperService.leagueRostersAsync(_isNull(leagueid) ? state.season.leagueId : leagueid)).data;
        commit('setYearRosters', yearRosters);     
      //}
    },
    async setLeagueDetails({commit, state}) {
      if(_isEmpty(state.details)){
        let leagueDetails = (await sleeperService.leagueAsync(state.season.leagueId)).data;
        commit('setLeagueDetails', leagueDetails);     
      }
    },
    async createRequestForChangeAsync ({ commit }, item) {
        await API.graphql(
            graphqlOperation(createRequestForChange, {
              input: item,
            })
          );
        commit('setRequestForChange', item);
    },
    async updateRequestForChangeAsync ({ commit }, item) {
        await API.graphql(
            graphqlOperation(updateRequestForChange, {
              input: item,
            })
          );
        commit('setRequestForChange', item);
    },
    async setRequestForChangesAsync ({ commit, state }) {
        if(_isEmpty(state.requestForChanges)){
          let items = await API.graphql(
              graphqlOperation(listRequestForChanges)
            );
          commit('setRequestForChanges', items.data.listRequestForChanges.items);
        }
    },
    async deleteRequestForChangeAsync({commit}, item){
        await API.graphql(
            graphqlOperation(deleteRequestForChange, {
              input: {id:item.id},
            })
          );
        commit('deleteRequestForChanges', item);
    },
    async createWheelOfLastAsync ({ commit }, item) {
        await API.graphql(
            graphqlOperation(createWheelOfLast, {
              input: item,
            })
          );
        commit('setWheelOfLast', item);
    },
    async updateWheelOfLastAsync ({ commit }, item) {
        await API.graphql(
            graphqlOperation(updateWheelOfLast, {
              input: item,
            })
          );
        commit('setWheelOfLast', item);
    },
    async setWheelOfLastsAsync ({ commit, state }) {
        if(_isEmpty(state.wheelOfLasts)){
          let items = await API.graphql(
              graphqlOperation(listWheelOfLastsByLeague, {
                leagueId:state.season.leagueId,
              })
            );
          commit('setWheelOfLasts', items.data.listWheelOfLastsByLeague.items);
        }
    },
    async deleteWheelOfLastAsync({commit}, item){
        await API.graphql(
            graphqlOperation(deleteWheelOfLast, {
              input: {id:item.id},
            })
          );
        commit('deleteWheelOfLasts', item);
    },

    async updateDuesAsync ({ commit }, item) {
        await API.graphql(
            graphqlOperation(updateDues, {
              input: item,
            })
          );
        commit('setDue', item);
    },
    async setDuesAsync ({ commit, state }) {
        if(_isEmpty(state.Dues)){
          let items = await API.graphql(
              graphqlOperation(listDuesByLeague, {
                leagueId:state.season.leagueId,
              })
            );
          commit('setDues', items.data.listDuesByLeague.items);
        }
    },
};

// mutations
const mutations = {
    setPlayer (state, player) {
      state.players.push(player);
    },
    setPastWeeklyRosterTransactions (state, item) {
      state.pastWeeklyRosterTransactions[item.week] = item.transactions;
    },
    setLeagueMatchups (state, matchups) {
      state.matchups = matchups;
    },
    setCurrentWeeksRosterTransactions (state, transactions) {
      state.currentWeekRosterTransactions = transactions;
    },
    setOwners(state, owners) {
      state.owners = owners;
    },
    setDraft(state, draft) {
      state.draft = draft;
    },
    setRosters(state, rosters) {
      state.rosters = rosters;
    },
    setYearRosters(state, yearRosters) {
      state.yearRosters = yearRosters;
    },
    setLeagueDetails(state, leagueDetails) {
      state.details = leagueDetails;
    },
    setSeason(state, season) {
      state.wheelOfLasts = [];
      state.dues = [];
      state.owners = [];
      state.rosters = [];
      state.yearRosters = [];
      state.details = {};
      state.draft = {};
      state.pastWeeklyRosterTransactions = {};
      state.currentWeekRosterTransactions = [];
      state.season = season;
      state.matchups = [];
    },
    setRequestForChange(state, requestForChange) {
      const findRequestForChange = function(item) {
          return item.id === requestForChange.id;
      };
      let index = _findIndex(state.requestForChanges, findRequestForChange, 0);
      if(index === -1 )
        state.requestForChanges.push(requestForChange);
      else
        state.requestForChanges[index] = requestForChange;
    },
    setRequestForChanges(state, requestForChanges) {
      state.requestForChanges = requestForChanges;
    },
    deleteRequestForChanges(state, requestForChange) {
      const removeRequestForChange = function(item){
        return item.id == requestForChange.id;
      };
      _remove(state.requestForChanges, removeRequestForChange);
    },
    setWheelOfLast(state, wheelOfLast) {
      const findWheelOfLast = function(item) {
          return item.id === wheelOfLast.id;
      };
      let index = _findIndex(state.wheelOfLasts, findWheelOfLast, 0);
      if(index === -1 )
        state.wheelOfLasts.push(wheelOfLast);
      else
        state.wheelOfLasts[index] = wheelOfLast;
    },
    setWheelOfLasts(state, wheelOfLasts) {
      state.wheelOfLasts = wheelOfLasts;
    },
    deleteWheelOfLasts(state, wheelOfLast) {
      const removeWheelOfLast = function(item){
        return item.id == wheelOfLast.id;
      };
      _remove(state.wheelOfLasts, removeWheelOfLast);
    },
    setDue(state, due) {
      const findDue = function(item) {
          return item.id === due.id;
      };
      let index = _findIndex(state.dues, findDue, 0);
      if(index === -1 )
        state.dues.push(due);
      else
        state.dues[index] = due;
    },
    setDues(state, dues) {
      state.dues = dues;
    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
