<template>
  <v-container>
    <SchedulesSkeleton v-if="loading" />
    <v-row v-if="!loading && isEmpty(matchups)" dense align="center" justify="center" class="fill-height no-gutters">
      <v-col cols="12" align="center" justify="center">
        <v-card>
          <v-card-title class="justify-center">
            Schedules have not been set yet.
          </v-card-title>
          <v-card-text class="justify-center">
            Please try back later or view another season.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-if="!loading && !isEmpty(matchups)">
      <v-card-text>
        <v-timeline dark>
          <v-timeline-item v-for="item in matchups" :key="item.week" small dense>
            <v-card class="elevation-2">
              <v-card-title :class="isEven(item.week) ? 'justify-end headline' : 'headline'">
                Week {{item.week}}
              </v-card-title>
              <v-card-text :class="isEven(item.week) ? 'theList' : ''">
                <ul>
                  <li v-for="matchup in item.matchups" :key="matchup.id">{{getOwnerName(matchup.teamOne)}} <b>VS</b> {{getOwnerName(matchup.teamTwo)}}</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SchedulesSkeleton from '@/components/common/skeletons/Schedules';
import { mapState, mapActions } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

export default {
  name: 'Schedules',
  async mounted () {
    try {
      this.loading = true;
      await this.setLeagueDetails();
      let totalWeeks = this.details.settings.playoff_week_start - 1;
      let setLeagueMatchupsTask = this.setLeagueMatchups(totalWeeks);
      let setRostersTask = this.setRosters();
      let setOwnersTask = this.setOwners();
      await setLeagueMatchupsTask;
      await setRostersTask;
      await setOwnersTask;

    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState('league', ['season', 'details', 'rosters', 'owners', 'matchups'])
  },
  methods: {
    ...mapActions('league', ['setLeagueDetails', 'setRosters', 'setOwners', 'setLeagueMatchups']),
    isEven (n) {
      return n % 2 == 0;
    },
    isEmpty (items) {
      if(!_isEmpty(items)){
        let item = _find(items, (item) => {
          if(!_isEmpty(item.matchups)){
            return true;
          }
        });
        return !item;
      }
      return true;
    },
    getOwnerName(team) {
      let me = this;
      let roster = _find(me.rosters, (item) => {
        return item.roster_id == team.roster_id;
      });
      let owner = _find(me.owners, (item) => {
        return item.user_id == roster.owner_id;
      });

      return owner.display_name + ' (' + team.points + ')' ?? 'N/A';
    }
  },
  components: {
    SchedulesSkeleton
  },
  data () {
    return {
      loading: true
    }
  },
};
</script>

<style scoped>
.theList {
  text-align: right;
  list-style-position: inside;
}
</style>
