<template>
  <v-container fill fluid>
    <DuesSkeleton v-if="loading" />
    <v-row v-if="!loading && isEmpty(dues)" dense align="center" justify="center" class="fill-height no-gutters">
      <v-col cols="12" align="center" justify="center">
        <v-card v-if="!loading || !isEmpty(dues)">
          <v-card-title class="justify-center">
            No Dues Data Availble
          </v-card-title>
          <v-card-text class="justify-center">
            Please check back later.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !isEmpty(dues)" dense align="center" justify="center">
      <v-col v-for="due in dues" :key="due.id" cols="auto">
        <v-card elevation="12" shaped>
          <v-card-title>
            {{displayTruncateEmail(due.user.email)}}
          </v-card-title>
          <v-card-text>
            Amount Due: ${{due.amountDue}} Amount Paid: ${{due.amountPaid}}
          </v-card-text>
          <v-card-actions>
            <v-chip v-if="due.amountDue == due.amountPaid" color="green">
              Paid
            </v-chip>
            <v-chip v-else-if="due.amountDue > due.amountPaid && due.amountPaid != 0" color="blue">
              Part Paid
            </v-chip>
            <v-chip v-else color="red">
              Not Paid
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
import { mapState, mapActions } from 'vuex';
import DuesSkeleton from '@/components/common/skeletons/Dues';
import _split from 'lodash/split';
import _isEmpty from "lodash/isEmpty";
// import DuesSkeleton from './skeletons/Dues';

export default {
  name: 'Dues',
  async mounted () {
    try {
      this.loading = true;

      let tasks = [];
      tasks.push(this.setDuesAsync());
      tasks.push(this.setUserAsync());

      await Promise.all(tasks);
    }
    finally {
      this.loading = false;
    }
  },
  data () {
    return {
      loading: true,
      toggle_exclusive: undefined,
      canSetPayment: false,
      currentUser: {}
    }
  },
  components: {
    DuesSkeleton
  },
  computed: {
    ...mapState('league', ['dues'])
  },
  methods: {
    ...mapActions('league', ['setDuesAsync', 'updateDuesAsync']),
    displayTruncateEmail (email) {
      return _split(email, '@')[0];
    },
    isEmpty (items) {
      return _isEmpty(items);
    },
    async toggleDue (item) {
      console.log(item);
    },
    async setUserAsync () {
      let userInfo = await Auth.currentUserInfo();
      this.currentUser = { id: userInfo.username, email: userInfo.attributes.email };
      this.canSetPayment = userInfo.username == 'a17add25-7543-462f-b45c-22b9b4a57736'; //TODO: Create Cognito Group so this permission over hard coded user id
    },
  }
};
</script>
