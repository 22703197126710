<template>
  <v-sheet :color="`grey darken-2`" class="pa-3">
    <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  name: 'SchedulesSkeleton',
};
</script>
