<template>
  <request-for-change />
</template>

<script>
import RequestForChange from '@/components/review/RequestForChange';

export default {
  components: { RequestForChange },
  name: 'Review',
};
</script>
