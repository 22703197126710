<template>
  <v-container fill-height fluid>
    <v-tabs center-active dark>
      <v-tab href="#rosters">Rosters</v-tab>
      <v-tab-item value="rosters">
        <UserCardList />
      </v-tab-item>
      <v-tab href="#rfa">RFA</v-tab>
      <v-tab-item value="rfa">
        <RfaPlayers />
      </v-tab-item>
      <v-tab href="#wheeloflast">Wheel of Last</v-tab>
      <v-tab-item value="wheeloflast">
        <wheel-of-last />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import RfaPlayers from '@/components/rfa/Players';
import UserCardList from '@/components/users/UserCardList';
import WheelOfLast from '@/components/users/WheelOfLast';
export default {
  name: 'Home',
  components: {
    RfaPlayers,
    UserCardList,
    WheelOfLast
  },
};
</script>

<style></style>
