<template>
  <v-container>
    <v-row dense align="center" justify="center">
      <v-col v-for="item in 12" :key="item" cols="4">
        <v-skeleton-loader type="card" shaped></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DuesSkeleton',
};
</script>
