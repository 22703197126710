<template>
  <v-sheet :color="`grey darken-2`" class="pa-3">
    <v-skeleton-loader type="table"></v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  name: 'RosterDetailsSkeleton',
};
</script>
