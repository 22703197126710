<template>
  <v-app>
    <!-- top nav -->
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon v-if="isSignedIn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>BOD ({{ season.year }})</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isSignedIn" class="hidden-sm-and-down">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-avatar color="white">
                <v-icon dark x-large color="#424242">mdi-account</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-spacer style="height: 12px;"></v-spacer>
            <SeasonPicker />
            <amplify-sign-out v-if="isSignedIn"></amplify-sign-out>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- left nav -->
    <v-navigation-drawer v-model="drawer" clipped app v-if="isSignedIn">
      <v-list dense>
        <v-list-item link v-for="item in items" :key="item.name" :to="item.link">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <SeasonPicker class="hidden-md-and-up" />
        <amplify-sign-out class="hidden-md-and-up" v-if="isSignedIn"></amplify-sign-out>
      </template>
    </v-navigation-drawer>
    <!-- main -->
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import SeasonPicker from '@/components/common/SeasonPicker';
import { mapState, mapMutations } from 'vuex';
import _isEmpty from "lodash/isEmpty";
export default {
  name: 'App',
  data () {
    return {
      title: 'BOD',
      isSignedIn: false,
      drawer: null,
      items: [
        { icon: 'mdi-football', name: 'Home', link: '/' },
        { icon: 'mdi-calendar', name: 'Schedules', link: '/schedules' },
        { icon: 'mdi-ruler', name: 'Standards', link: '/standards' },
        { icon: 'mdi-head-check', name: 'Review', link: '/review' },
        { icon: 'mdi-cash-multiple', name: 'Dues', link: '/dues' },
        { icon: 'mdi-gavel', name: 'Auction (Beta)', link: '/auction' },
        { icon: 'mdi-account-multiple', name: 'League', link: '/about' },
      ],
    };
  },
  components: {
    SeasonPicker
  },
  methods: {
    ...mapMutations('league', ['setSeason'])
  },
  computed: {
    ...mapState('league', ['season']),
  },
  mounted () {
    if (_isEmpty(this.season))
      this.setSeason(SeasonPicker.data().seasons[0]);
  },
  beforeCreate () {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.isSignedIn = true;
        this.$router.push('/');
      }
      if (payload.event === 'signOut') {
        this.$router.push('/login');
        this.isSignedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.isSignedIn = true;
      })
      .catch(() => (this.isSignedIn = false));
  },
};
</script>
