<template>
  <v-container fill-height fluid>
    <router-link style="font-size:12px" to="/">
      &lt; Back to League
    </router-link>
    <v-tabs center-active dark>
      <v-tab href="#active">Active</v-tab>
      <v-tab-item value="active">
        <RosterDetails />
      </v-tab-item>
      <v-tab href="#deadyears">Dead Years</v-tab>
      <v-tab-item value="deadyears">
        <DeadYears />
      </v-tab-item>
      <v-tab href="#whatif">What If</v-tab>
      <v-tab-item value="whatif">
        <WhatIf />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import RosterDetails from '@/components/users/RosterDetails.vue';
import DeadYears from '@/components/users/DeadYears.vue';
import WhatIf from '@/components/users/WhatIf.vue';

export default {
  name: 'Roster',
  data () {
    return {
      header: 'Roster',
    };
  },
  components: {
    RosterDetails,
    DeadYears,
    WhatIf
  },
};
</script>
