<template>
  <v-list-item class="grow">
    <v-list-item-avatar color="grey darken-3" :size="owner.avatar_size ? owner.avatar_size : 40">
      <v-img class="elevation-6" alt="" :src="owner.avatar_thumb" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-subtitle>{{ owner.display_name }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'ListItemAvatar',
  props: {
    owner: Object,
  }
}
</script>
