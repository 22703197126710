<template>
  <section class="vue-winwheel">
    <div class="mobile-container">
      <div class="wheel-wrapper">
        <v-icon>mdi-arrow-down</v-icon>
        <div class="canvas-wrapper">
          <canvas id="canvas" width="310" height="310">
            <p style="{color: white}" align="center">Sorry, your browser doesn't support canvas. Please try Google Chrome.</p>
          </canvas>
        </div>
        <v-btn :disabled="!isEmpty(prizeName)" class="primary" elevation="2" href="javascript(void);" @click.prevent="startSpin()" v-if="(!loadingPrize && !wheelSpinning) && canSpin">Spin</v-btn>
      </div>
    </div>
    <div class="custom-modal modal-mask" id="modalSpinwheel" v-if="modalPrize">
      <div slot="body">
        <a href="" @click.prevent="hidePrize()" class="modal-dismiss">
          <i class="icon_close"></i>
        </a>
        <h1> {{prizeName}}</h1>
      </div>
    </div>
  </section>
</template>


<script>
import * as Winwheel from '@/plugins/Winwheel';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

export default {
  name: 'VueWinWheel',
  props: {
    segments: {
      default () {
        return [];
      }
    },
    canSpin: {
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      loadingPrize: false,
      theWheel: null,
      modalPrize: false,
      wheelPower: 1,
      wheelSpinning: false,
      prizeName: '',
      WinWheelOptions: {
        textFontSize: 14,
        innerRadius: 10,
        lineWidth: 0,
        animation: {
          type: 'spinOngoing',
          duration: 0.5
        }
      }
    }
  },
  computed: {
    wheelSegments () {
      let me = this;
      this.segments.forEach((item, index) => {
        if (me.isEven(index)) {
          item['textFillStyle'] = '#fff';
          item['fillStyle'] = '#000';
        }
        else {
          item['textFillStyle'] = '#000';
          item['fillStyle'] = '#fadede';
        }
      });
      return this.segments;
    }
  },
  methods: {
    isEven (n) {
      return n % 2 == 0;
    },
    showPrize () {
      this.modalPrize = true;
    },
    hidePrize () {
      this.modalPrize = false;
    },
    startSpin () {
      if (this.wheelSpinning === false) {
        this.theWheel.startAnimation();
        this.wheelSpinning = true;
        this.theWheel = new Winwheel.Winwheel({
          ...this.WinWheelOptions,
          numSegments: this.segments.length,
          segments: this.wheelSegments,
          animation: {
            type: 'spinToStop',
            duration: 5,
            spins: 5,
            callbackFinished: this.onFinishSpin
          }
        });
        // example input prize number get from Backend
        // Important thing is to set the stopAngle of the animation before stating the spin.
        var prizeNumber = Math.floor(Math.random() * this.segments.length); // or just get from Backend
        var stopAt = 360 / this.segments.length * prizeNumber - 360 / this.segments.length / 2; // center pin
        // var stopAt = 360 / this.segments.length * prizeNumber - Math.floor(Math.random() * 60) //random location
        this.theWheel.animation.stopAngle = stopAt;
        this.theWheel.startAnimation();
        this.wheelSpinning = false;
      }
    },
    resetWheel () {
      this.theWheel = new Winwheel.Winwheel({
        ...this.WinWheelOptions,
        numSegments: this.segments.length,
        segments: this.wheelSegments
      });
      if (this.wheelSpinning) {
        this.theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
      }
      this.theWheel.rotationAngle = this.getRotationAngel();
      this.theWheel.draw(); // Call draw to render changes to the wheel.
      this.wheelSpinning = false; // Reset to false to power buttons and spin can be clicked again.
    },
    initSpin () {
      let selectedSegment = this.getSelectedSegment();
      this.loadingPrize = true;
      this.resetWheel();
      this.loadingPrize = false;

      if (!_isEmpty(selectedSegment)) {
        this.prizeName = selectedSegment.text;
        this.modalPrize = true;
      }
    },
    onFinishSpin (indicatedSegment) {
      this.prizeName = indicatedSegment.text;
      this.showPrize();
      let result = _pick(indicatedSegment, ['id', 'leagueId', 'text', 'description', 'user', 'selected', 'createdAt', 'updatedAt']);
      result.selected = true;
      this.$emit('set-spin-selection', result);
    },
    getRotationAngel () {
      if (_isEmpty(this.segments))
        return 0;

      let index = _findIndex(this.segments, (item) => {
        return item.selected === true;
      }, 0);

      if (index === -1)
        return 0;

      const startSegment = 360 / this.segments.length;
      const centerSegment = startSegment / 2;
      return 360 - (((index + 1) * startSegment) - centerSegment);
    },
    isEmpty (item) {
      return _isEmpty(item);
    },
    getSelectedSegment () {
      let me = this;
      let selectedSegment = _find(me.segments, (item) => {
        return item.selected === true;
      });

      return selectedSegment;
    }
  },
  mounted () {
    this.initSpin();
  },
}
</script>

<style scoped>
.vue-winwheel {
  text-align: center;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.vue-winwheel h1 {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #2196f3;
  font-size: 36px;
  line-height: 20px;
  letter-spacing: 4px;
  margin: 0;
}
.vue-winwheel h2 {
  margin: 0;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content {
  width: calc(100vw - 30px);
  padding-top: 52px;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content h2 {
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 1.1px;
  margin: 0;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content p {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: black;
  text-align: center;
  line-height: 25px;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content p strong {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content .modal-dismiss {
  top: 12px;
  right: 12px;
}
.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content .modal-dismiss i.icon_close {
  font-size: 30px;
  color: #2196f3;
}
.vue-winwheel canvas#canvas {
  position: relative;
}
.vue-winwheel .canvas-wrapper {
  position: relative;
}
.vue-winwheel .canvas-wrapper:after {
  content: "";
  display: block;
  width: 42px;
  background: #2196f3;
  height: 42px;
  position: absolute;
  left: calc(50% - 25px);
  margin: auto;
  border-radius: 100%;
  top: calc(50% - 29px);
  border: 5px solid white;
  box-sizing: content-box;
}
.vue-winwheel .canvas-wrapper:before {
  content: "";
  display: block;
  width: 310px;
  background: #0f0f0f;
  height: 310px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
  top: 0;
}
.vue-winwheel .wheel-wrapper {
  position: relative;
}
.vue-winwheel .wheel-wrapper:before {
  content: "";
  width: 62px;
  height: 47px;
  position: absolute;
  top: -10px;
  left: calc(50% - 31px);
  right: 0;
  display: block;
  z-index: 99999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
