<template>
  <v-card flat>
    <v-card-text>
      <ul>
        <li>League will consist of 12 teams</li>
        <li>Regular season schedule will be 13 weeks</li>
        <li>Scoring is .5 point PPR (Points Per Reception)</li>
        <li>
          Free Agent Acquisition will be auction style waivers ($100 FA funds
          every year)
        </li>
        <li>Unused FA Funds will roll over to the following season</li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MajorComponents',
  data () {
    return {
      header: 'MajorComponents',
    };
  },
};
</script>
