<template>
  <v-card flat>
    <v-card-text>
      <v-timeline dark>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline">
              April 1st
            </v-card-title>
            <v-card-text>
              <ul>
                <li>Annual League Review</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline justify-end">
              May 1st
            </v-card-title>
            <v-card-text class="theList">
              <ul>
                <li>New league year begins</li>
                <li>League is Activated</li>
                <li>Free Agency is activated</li>
                <li>Draft day date & location announced</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline">
              June 1st
            </v-card-title>
            <v-card-text>
              <ul>
                <li>League fees due ($150)</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline justify-end">
              January
            </v-card-title>
            <v-card-text class="theList">
              <ul>
                <li>Payouts
                  <ol>
                    <li>$1200</li>
                    <li>$300</li>
                    <li>$100</li>
                  </ol>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Calendar',
  data () {
    return {
      header: 'Calendar',
    };
  },
};
</script>

<style scoped>
.theList {
  text-align: right;
  list-style-position: inside;
}
</style>
