<template>
  <v-container fluid>
    <v-tabs vertical dark right>
      <v-tab href="#major-components">Major Components</v-tab>
      <v-tab-item value="major-components">
        <MajorComponents />
      </v-tab-item>
      <v-tab href="#playoffs">Playoffs</v-tab>
      <v-tab-item value="playoffs">
        <Playoffs />
      </v-tab-item>
      <v-tab href="#changes">Changes</v-tab>
      <v-tab-item value="changes">
        <Changes />
      </v-tab-item>
      <v-tab href="#calendar">Calendar</v-tab>
      <v-tab-item value="calendar">
        <Calendar />
      </v-tab-item>
      <v-tab href="#format">Format</v-tab>
      <v-tab-item value="format">
        <Format />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import Format from '@/components/standards/Format';
import Changes from '@/components/standards/Changes';
import Calendar from '@/components/standards/Calendar';
import MajorComponents from '@/components/standards/MajorComponents';
import Playoffs from '@/components/standards/Playoffs';
export default {
  name: 'Standards',
  data () {
    return {
      header: 'Rules and Regulation',
    };
  },
  components: {
    Format,
    Changes,
    Calendar,
    MajorComponents,
    Playoffs,
  },
};
</script>

<style>
h1 {
  text-align: center;
  padding: 2rem;
}
</style>
