<template>
  <v-card flat>
    <v-card-text>
      <ol type="A">
        <li>
          <p>
            <i>Rosters/ Contract Years</i> - Rosters will consist of a
            maximum of {{size(details.roster_positions)}} active spots, {{details.settings.taxi_slots}} DTS spots and {{details.settings.reserve_slots}} IR spot. During
            the season, the amount of players on the active roster and at
            each position is up to the owner, but must contain enough
            players to field a full lineup at all times (this includes
            accounting for byes and injuries). Failure to field a starting
            lineup of eligible players will result in a $5 Free Agent Fund
            (FAF) per violation. Eligible players are defined as healthy
            players not on their bye week that are active on a NFL roster.
            Each owner will be allotted 50 contract years to divide
            amongst the players on their roster.
          </p>
          <p>
            Every player on an owner’s active roster must be allotted
            contract years. The limit for contract years that can be
            assigned to a player at one time is 6 years. No player can be
            on an owner’s active roster without contract years allotted.
            Once contract years are assigned, there is no change in the
            amount of years assigned to that player until the amount of
            allotted years has run out.
          </p>
          <p>
            a player with contract years remaining before the end of the
            term of the contract will result in a penalty. The penalty
            will be 1 year plus $5 for every additional contract year
            remaining in order to buy out the player's contract. If the
            player only has 1 year remaining, then there is no buyout.
            Owners cannot buy out contract years in a trade.
          </p>
          <p>
            Once the terms of players' contract years expire, those
            players will become restricted free agents (RFAs). Traded
            players still carry assigned contract years. If a player
            retires after the beginning of the league year, then the
            remainder of contract years assigned to that player are
            voided. Instead there will be a 1 year contract hit in lieu of
            being forced to carry out the remainder of the contract.
            Players that retire before the league year begins will have
            contracts voided without penalty.
          </p>
        </li>
        <li>
          <p>
            <i>Rookie Draft</i> - The Rookie Draft will be held every year
            during draft weekend. It will consist of {{details.settings.draft_rounds}} rounds in a
            traditional draft order made up of reverse order of the
            previous year’s standings. This draft will not snake. This
            means that the draft order will stay the same in each of the {{details.settings.draft_rounds}}
            rounds. Players must be added to either regular roster or Taxi
            Squad from this draft.
          </p>
        </li>
        <li>
          <p>
            <i>Taxi Squad</i> - The Taxi Squad is reserved for players
            that have been in the NFL for {{details.settings.taxi_years}} years or less. These players
            while part of your team, do not have contract years assigned
            to them. They do not contribute towards point totals. Once a
            player is moved from the Taxi Squad onto the main roster, he
            must have contract years allotted to him. Owners can cut Taxi
            Squad players from their roster without penalty. Players cut
            from the Taxi Squad will fall into the free agency pool.
            Players from the Rookie draft, RFA, or free agency can be
            added to the Taxi Squad as long as they have been in the NFL
            for less than {{details.settings.taxi_years}} years. If a player is traded from an active
            roster to another owner, he cannot be moved to the Taxi Squad
            since he would have contract years already assigned to him.
          </p>
        </li>
        <li>
          <p>
            <i>Restricted Free Agency (RFA)</i> - Every year, each team
            will have players with expiring contracts. Those players will
            be known as restricted free agents. Every year during draft
            weekend, owners will bid on these players using Free Agent
            Funds. You cannot trade RFA players prior to the RFA period or
            once RFA begins. Owners with the highest bid will be awarded
            that player, and will be forced to assign contract years.
            Current owners of RFAs will have an opportunity to retain
            players by matching the highest bid plus a tiered amount based
            off the points that player scored the year prior. If no one
            bids on a player, owners wishing to retain RFAs owe just the
            tiered amount.
          </p>
          <p>
            The tiered breakdown is as follows:
          <ul>
            <li>0 - 100 points = $2</li>
            <li>101 - 200 points = $4</li>
            <li>201+ points = $6</li>
          </ul>
          </p>
        </li>
        <li>
          <p>
            <i>Free Agency</i> - Free Agents can be acquired throughout
            the season using Free Agent funds. Owners will bid on free
            agents via auction-style waivers.
          </p>
        </li>
        <li>
          <p>
            <i>Waivers</i> - Each week, team owners will have the
            opportunity to pick up free agents. They must do so using free
            agent funds. Owners with the highest bid will be awarded the
            player. They then must assign contract years to that player.
            Waivers will process every day except Monday at 12EST. Upon a
            tie, owners will be forced through a 2nd round of blind bids
            to be processed by the Commissioner, or by an appointed 3rd
            party if the Commissioner is involved in bidding.
          </p>
        </li>
        <li>
          <p>
            <i>Trades</i> - Owners are free to trade players, draft picks,
            and FA funds. Contract years cannot be traded. Only draft
            picks up to 2 years in the future can be traded. Trades are
            approved by the Commissioner. Multiple teams can be involved
            in a trade. The Commissioner reserves the right to put trades
            up for league review at any given time. If collusion is
            suspected, the Commissioner will provision a committee to
            investigate. Penalties will be decided on a case by case
            basis.
          </p>
        </li>
        <li>
          <p>
            <i>Injured Reserve</i> - Each team will have {{details.settings.reserve_slots}} Injured Reserve
            spot (IR). Players that are placed on IR in the NFL are
            eligible. Players that are placed on the PUP list, but return
            to an NFL active roster are eligible to be taken off IR;
            otherwise if a player is placed in the IR slot, he must remain
            there for the entirety of the season. Players on IR only
            account for 1 contract year for the time frame they are in the
            slot.
          </p>
        </li>
        <li>
          <i>Player Scoring</i>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Passing</v-list-item-title>
                <v-list-item-subtitle>25 yards per point ({{round(details.scoring_settings.pass_yd, 2)}} per yard)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Rushing</v-list-item-title>
                <v-list-item-subtitle>10 yards per point ({{round(details.scoring_settings.rush_yd, 2)}} per yard)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Receiving</v-list-item-title>
                <v-list-item-subtitle>10 yards per point ({{round(details.scoring_settings.rec_yd, 2)}} per yard)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Reception</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.rec, 2)}} point</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Passing TD</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.pass_td, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Rushing TD</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.rush_td, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Receiving TD</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.rec_td, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Turnovers (Interception or Fumble
                  lost)</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.pass_int, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>2 point conversion</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.pass_2pt, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Return TD</v-list-item-title>
                <v-list-item-subtitle>{{round(details.scoring_settings.kr_td, 0)}} points</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </li>
      </ol>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _round from 'lodash/round';
import _size from 'lodash/size';

export default {
  name: "Format",
  async mounted () {
    try {
      this.loading = true;
      await this.setStateAsync();
    } finally {
      this.loading = false;
    }
  },
  data: function () {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState('league', ['details']),
  },
  methods: {
    ...mapActions('league', ['setLeagueDetails']),
    async setStateAsync () {
      await this.setLeagueDetails();
    },
    round (value, precision) {
      return _round(value, precision);
    },
    size (item) {
      return _size(item);
    }
  }
};
</script>
