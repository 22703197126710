<template>
  <v-row dense align="center" justify="center">
    <v-col v-for="sk in 12" :key="sk" cols="auto">
      <template>
        <v-sheet :color="`grey darken-2`" class="pa-3">
          <v-skeleton-loader class="mx-auto" max-width="400" min-width="400" type="card"></v-skeleton-loader>
        </v-sheet>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'RequestForChangeSkeleton',
};
</script>
