<template>
  <v-select v-model="select" @change="setSeasonLocal" :items="seasons" item-text="year" item-value="leagueId" label="Season" dense return-object></v-select>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import _isEmpty from "lodash/isEmpty";
export default {
  name: 'SeasonPicker',
  data: () => ({
    select: {}
  }),
  mounted () {
    this.setDefaultSeasonSelect();
  },
  methods: {
    ...mapMutations('league', ['setSeason']),
    setSeasonLocal (item) {
      this.setSeason(item);
      this.$router.go();
    },
    setDefaultSeasonSelect () {
      if (!_isEmpty(this.season))
        this.select = this.season;
      else
        this.select = this.seasons[0];
    }
  },
  computed: {
    ...mapState('league', ['seasons','season']),
  },
}
</script>
