<template>
  <v-container fill-height fluid>
    <RequestForChangeSkeleton v-if="loading" />
    <v-row v-if="!loading" dense>
      <v-col cols="12" align="right" justify="right">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2 primary" fab dark v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              Request For Change
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field disabled label="User" :value="currentUser.email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="firstName" label="First Name*" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="lastName" label="Last Name*" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="propsal" label="Propsal*" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="reason" required>
                      <template v-slot:label>
                        <div>
                          Reason*
                        </div>
                      </template>
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu v-model="effectiveDateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="effectiveDate" label="Effective Date*" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="effectiveDate" @input="effectiveDateSelector = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="primary" color="darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn class="primary" color="darken-1" text @click="submitRequestForChange">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="!loading && isEmpty(requestForChanges)" dense align="center" justify="center" class="fill-height no-gutters">
      <v-col cols="12" align="center" justify="center">
        <v-card v-if="!loading || !isEmpty(requestForChanges)">
          <v-card-title class="justify-center">
            No Request For Change Found
          </v-card-title>
          <v-card-text class="justify-center">
            Please submit a Request For Change.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if=" !loading && !isEmpty(requestForChanges)" dense align="center" justify="center">
      <v-col v-for="rfc in requestForChanges" :key="rfc.id" cols="auto">
        <v-card class="mx-auto" max-width="500" min-width="500">
          <v-icon v-if="currentUser.id == rfc.userId" @click="deleteRequestForChangeAsync(rfc)" style="float:right; padding-top:5px; padding-right:5px;">mdi-close</v-icon>
          <v-card-title>
            {{rfc.proposal}}
          </v-card-title>
          <v-card-text>
            {{rfc.reason}}
          </v-card-text>
          <v-card-text>
            Effective Date: {{rfc.effectiveDate}}
          </v-card-text>
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-icon class="text-icon-margin">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" dense :color="some(rfc.yes, currentUser) ? 'blue darken-2' : ''" @click="upVoteAsync(rfc, currentUser)">mdi-thumb-up</v-icon>
                    <div style="padding-left:2px">{{size(rfc.yes)}}</div>
                  </template>
                  <span max->
                    <ol>
                      <li v-for="user in rfc.yes" :key="user.id">
                        {{displayTruncateEmail(user.email)}}
                      </li>
                    </ol>
                  </span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-icon class="text-icon-margin">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" dense :color="some(rfc.no, currentUser) ? 'red darken-2' : ''" @click="downVoteAsync(rfc, currentUser)">mdi-thumb-down</v-icon>
                    <div style="padding-left:2px">{{size(rfc.no)}}</div>
                  </template>
                  <span max->
                    <ol>
                      <li v-for="user in rfc.no" :key="user.id">
                        {{displayTruncateEmail(user.email)}}
                      </li>
                    </ol>
                  </span>
                </v-tooltip>
              </v-list-item-icon>
              <!-- <v-list-item-icon class="text-icon-margin">
                <v-icon dense>mdi-message-text</v-icon>
              </v-list-item-icon> -->
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
import { mapState, mapActions } from 'vuex';
import _size from 'lodash/size';
import _some from 'lodash/some';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _split from 'lodash/split';
import RequestForChangeSkeleton from './skeletons/RequestForChange';

export default {
  name: 'RequestForChange',
  async mounted () {
    try {
      this.loading = true;
      await this.setUserAsync();
      await this.setRequestForChangesAsync();
    } finally {
      this.loading = false;
    }
  },
  components: {
    RequestForChangeSkeleton
  },
  data: function () {
    return {
      currentUser: {},
      dialog: false,
      loading: true,
      firstName: '',
      lastName: '',
      propsal: '',
      reason: '',
      effectiveDateSelector: false,
      effectiveDate: new Date().toISOString().substr(0, 10),
    };
  },
  computed: {
    ...mapState('league', ['requestForChanges', 'season'])
  },
  methods: {
    ...mapActions('league', [
      'setRequestForChangesAsync',
      'createRequestForChangeAsync',
      'updateRequestForChangeAsync',
      'deleteRequestForChangeAsync'
    ]),
    isEmpty (items) {
      return _isEmpty(items);
    },
    size (item) {
      return _size(item);
    },
    some (items, value) {
      return _some(items, value);
    },
    displayTruncateEmail (email) {
      return _split(email, '@')[0];
    },
    async upVoteAsync (item, currentUser) {
      if (_isNil(item.no))
        item.no = [];
      if (_isNil(item.yes))
        item.yes = [];

      // let noExists = _includes(item.no, currentUser.userName);
      // let yesExists = _includes(item.yes, currentUser.userName);
      let noExists = _some(item.no, currentUser);
      let yesExists = _some(item.yes, currentUser);

      if (!noExists && !yesExists) {
        item.yes.push(currentUser);
        await this.updateRequestForChangeAsync(item);
      }
      else if (noExists && !yesExists) {
        item.yes.push(currentUser);
        _remove(item.no, function (item) {
          return item.id === currentUser.id;
        });
        await this.updateRequestForChangeAsync(item);
      }
    },
    async downVoteAsync (item, currentUser) {
      if (_isNil(item.no))
        item.no = [];
      if (_isNil(item.yes))
        item.yes = [];

      // let noExists = _includes(item.no, currentUser.userName);
      // let yesExists = _includes(item.yes, currentUser.userName);
      let noExists = _some(item.no, currentUser);
      let yesExists = _some(item.yes, currentUser);

      if (!noExists && !yesExists) {
        item.no.push(currentUser);
        await this.updateRequestForChangeAsync(item);
      }
      else if (!noExists && yesExists) {
        item.no.push(currentUser);
        _remove(item.yes, function (item) {
          return item.id === currentUser.id;
        });
        await this.updateRequestForChangeAsync(item);
      }
    },
    async setUserAsync () {
      let userInfo = await Auth.currentUserInfo();
      this.currentUser = { id: userInfo.username, email: userInfo.attributes.email };
    },
    async submitRequestForChange () {
      const currentTime = new Date().toUTCString();
      let form = {
        leagueId: this.season.leagueId,
        ownerId: 'TBD',
        proposal: this.propsal,
        reason: this.reason,
        effectiveDate: this.effectiveDate,
        status: 'Pending',
        yes: [{ id: this.currentUser.userName, email: this.currentUser.userEmail }],
        no: [],
        firstName: this.firstName,
        lastName: this.lastName,
        userId: this.currentUser.userName,
        userEmail: this.currentUser.userEmail,
        createdAt: currentTime,
        updatedAt: currentTime
      };
      await this.createRequestForChangeAsync(form);
      this.dialog = false;
    }
  }
};
</script>

<style>
.text-icon-margin {
  margin: 15px !important;
}
</style>
