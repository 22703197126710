
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import league from './modules/league';

Vue.use(Vuex);

const vuexPersist = createPersistedState({
  key: 'bod-state-v2',
  reducer: state => ({
    league: {
      players: state.league.players,
      //pastWeeklyRosterTransactions: state.league.pastWeeklyRosterTransactions,
      details: state.league.details,
      season: state.league.season
    }}),
  saveState: (key, state, storage) => {
    requestIdleCallback(() => {
      storage.setItem(key, JSON.stringify(state));
    });
  },
});

export default new Vuex.Store({
  modules: {
    league
  },
  plugins: [vuexPersist]
});
