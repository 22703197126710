var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill":"","fluid":""}},[(_vm.loading)?_c('RosterDetailsSkeleton'):_vm._e(),(!_vm.loading)?_c('v-card',[_c('v-card-title',[_c('v-list-item',{staticClass:"grow"},[_c('v-list-item-content',[(_vm.owner.metadata.team_name)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.owner.metadata.team_name)+" ")]):_c('v-list-item-title',[_vm._v("N/A")]),_c('v-list-item-subtitle',[_c('ListItemAvatar',{attrs:{"owner":_vm.owner}})],1)],1),_c('v-list-item-content',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dark":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"headers":_vm.getHeaders(),"items":_vm.players,"footer-props":{
        'items-per-page-text': 'Players per page',
        'items-per-page-options': [5, 10, -1],
      },"item-key":"full_name","dense":"","dark":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.previous_nick_name",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [(!item.hasGoodCY)?_c('v-chip',{attrs:{"color":_vm.getColor(item)}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(item.hasGoodCY)?_c('div',[_vm._v(_vm._s(value))]):_vm._e()]}},{key:"item.nick_name",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [(!item.hasGoodCY)?_c('v-chip',{attrs:{"color":_vm.getColor(item)}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(item.hasGoodCY)?_c('div',[_vm._v(_vm._s(value))]):_vm._e()]}}],null,false,478893479)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }