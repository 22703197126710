<template>
  <amplify-authenticator username-alias="email">
    <!-- <amplify-sign-up slot="sign-up" username-alias="email" :form-fields.prop="formFields"></amplify-sign-up> -->
    <amplify-sign-in slot="sign-in" username-alias="email" hide-sign-up="true"></amplify-sign-in>
  </amplify-authenticator>
</template>
<script>
export default {
  name: 'Login',
  data () {
    return {
      formFields: [
        {
          type: 'email',
          label: 'Enter Email',
          placeholder: 'Email',
          required: true,
        },
        {
          type: 'password',
          label: 'Enter Password',
          placeholder: 'Password',
          required: true,
        },
      ],
    };
  },
};
</script>

<style>
:root {
  --amplify-primary-color: #26c6da;
  --amplify-primary-tint: #424242;
  --amplify-primary-shade: #26c6da;
}
</style>
