<template>
  <v-container fill-height fluid>
    <v-tabs center-active dark>
      <v-tab href="#house">House</v-tab>
      <v-tab-item value="house">
        <AuctionHouse />
      </v-tab-item>
      <v-tab href="#report">Reports</v-tab>
      <v-tab-item value="report">
        <AuctionReports />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import AuctionReports from '@/components/auction/Reports';
import AuctionHouse from '@/components/auction/House';
export default {
  name: 'Auction',
  components: {
    AuctionReports,
    AuctionHouse
  },
};
</script>

<style></style>