import axios from 'axios';

export default {
  leagueAsync(bodLeagueId){
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId;
    return axios({
      method: 'get',
      url: url
    });
  },
  leagueRostersAsync (bodLeagueId) {
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId + '/rosters';
    return axios({
      method: 'get',
      url: url
    });
  },
  leagueUsersAsync (bodLeagueId) {
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId + '/users';
    return axios({
      method: 'get',
      url: url
    });
  },
  rosterTransactionsAsync (bodLeagueId, week) {
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId + '/transactions/' + week;
    return axios({
      method: 'get',
      url: url
    });
  },
  leagueMatchupsAsync (bodLeagueId, week) {
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId + '/matchups/' + week;
    return axios({
      method: 'get',
      url: url
    });
  },
  leagueDraftAsync (bodLeagueId) {
    let url = 'https://api.sleeper.app/v1/league/' + bodLeagueId + '/drafts';
    return axios({
      method: 'get',
      url: url
    });
  }
};
