<template>
  <v-container>
    <v-row dense align="center" justify="center">
      <v-col cols="12" align="left" justify="left">
        <v-skeleton-loader type="avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col cols="12" md="6">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-container>
          <v-row v-for="i in 4" :key="i" dense align="center" justify="center">
            <v-col cols="12">
              <v-skeleton-loader type="list-item"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WheelOfLastSkeleton',
};
</script>
