<template>
  <v-card flat>
    <v-card-text>
      <p>
        The month of April every year will be used for an annual review of
        league rules and potential changes. No changes to the format of the
        league will be made outside of this month. It is the responsibility of
        league members to recommend desired changes throughout this month.
      </p>
      <p>
        Decisions on potential changes will be at the Commissioner's discretion.
        The amount of support from the league regarding potential changes will
        carry influence as to whether the change will be put up to a vote.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Changes',
  data () {
    return {
      header: 'Changes',
    };
  },
};
</script>
