import VueRouter from "vue-router";
import { Auth } from "aws-amplify";
import Login from "./views/Login";
import Review from "./views/Review";
import Standards from "./views/Standards";
import About from "./views/About"
import Home from "./views/Home"
import Schedules from "./views/Schedules"
import Roster from "./views/sub/Roster"
import Dues from "./views/Dues"
import Auction from "./views/Auction"

const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { allowAnonymous: true },
  },
  { path: "/home", name: "HomeAlt", component: Home },
  { path: "/roster/:owner_id", name: "Roster", component: Roster },
  { path: "/standards", name: "Standards", component: Standards },
  { path: "/about", name: "About", component: About },
  { path: "/review", name: "Review", component: Review },
  { path: "/schedules", name: "Schedules", component: Schedules },
  { path: "/dues", name: "Dues", component: Dues },
  { path: "/auction", name: "Auction", component: Auction },
  { path: "*", component: Login }, //Make 404 View
];

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => !record.meta.allowAnonymous)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: "/login",
        });
      });
  }
  next();
});

export default router;
