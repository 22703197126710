<template>
  <v-container fill-height fluid>
    <v-row dense align="center" justify="center">
      <v-col v-for="user in users" :key="user.id" cols="auto">
        <v-card class="mx-auto" color="#26c6da" dark max-width="400">
          <v-card-title>
            <!-- Display name -->
            <v-list-item align="center" justify="center">
              <v-list-item-content>
                <v-list-item-title>{{user.display_name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>
          <v-list-item align="center" justify="center">
            <amplify-s3-image :img-key="user.image_key" />
          </v-list-item>
          <v-card-subtitle>
            <v-list-item align="center" justify="center">
              <v-list-item-content>
                <v-list-item-subtitle>Status: {{user.status}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Members",
  data () {
    return {
      users: [
        {
          display_name: "Michael Varnum",
          image_key: "aboutus/michael_varnum.jpg",
          status: "Comissioner"
        },
        {
          display_name: "Curtis Wellborn",
          image_key: "aboutus/curtis_wellborn.jpg",
          status: "Treasurer"
        },
        {
          display_name: "Justin Bush",
          image_key: "aboutus/justin_bush.jpg",
          status: "Ex-Comish"
        },
        {
          display_name: "Thomas Stewart",
          image_key: "aboutus/thomas_stewart.jpg",
          status: "Member"
        },
        {
          display_name: "Chad Yoder",
          image_key: "aboutus/chad_yoder.jpg",
          status: "Member"
        },
        {
          display_name: "Jason Fincham",
          image_key: "aboutus/jason_fincham.jpg",
          status: "Member"
        },
        {
          display_name: "Kenny Schreiber",
          image_key: "aboutus/kenny_schreiber.jpg",
          status: "Member"
        },
        {
          display_name: "Ian Pianin",
          image_key: "aboutus/ian_pianin.jpg",
          status: "Member"
        },
        {
          display_name: "Steven Johnson",
          image_key: "aboutus/steven_johnson.jpg",
          status: "Member"
        },
        {
          display_name: "David Hudson",
          image_key: "aboutus/david_hudson.jpg",
          status: "Member"
        },
        {
          display_name: "Garrett Thomas",
          image_key: "aboutus/garret_thomas.jpg",
          status: "Member"
        },
        {
          display_name: "Tony Nguyen",
          image_key: "aboutus/tony_nguyen.jpg",
          status: "Member"
        }
      ]
    }
  }
};
</script>


<style>
h1 {
  text-align: center;
  padding: 2rem;
}
amplify-s3-image {
  --height: 150px;
  --width: 150px;
}
</style>
