<template>
  <v-container fill-height fluid>
    <WheelOfLastSkeleton v-if="loading" />
    <v-row v-if="!loading" dense>
      <v-col cols="12" align="left" justify="left">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2 primary" fab dark v-bind="attrs" v-on="on">
              <v-icon v-if="isEmpty(currentUserWheelOfLast['id'])" dark>
                mdi-plus
              </v-icon>
              <v-icon v-else dark>
                mdi-settings
              </v-icon>
            </v-btn>
          </template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <v-card-title>
                Wheel Of Last
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field disabled label="User" :value="currentUser.email"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="text" :rules="titleRules" :counter="16" label="Title*" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="description" :rules="descriptionRules" :counter="800" required>
                        <template v-slot:label>
                          <div>
                            Description*
                          </div>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary" color="darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn :disabled="!valid" class="primary" color="darken-1" text @click="submitWheelOfLast">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="!loading && isEmpty(wheelOfLasts)" dense align="center" justify="center" class="fill-height no-gutters">
      <v-col cols="12" align="center" justify="center">
        <v-card v-if="!loading || !isEmpty(wheelOfLasts)">
          <v-card-title class="justify-center">
            No Wheel of Last Submitted
          </v-card-title>
          <v-card-text class="justify-center">
            Please submit a Wheel of Last.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if=" !loading && !isEmpty(wheelOfLasts)" dense align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <VueWinwheel :segments="wheelOfLasts" :canSpin="canSpin" @set-spin-selection="setSpinSelected" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-expansion-panels>
          <v-expansion-panel v-for="wheelOfLast in wheelOfLasts" :key="wheelOfLast.id">
            <v-expansion-panel-header :class="wheelOfLast.selected ? 'text-selected': ''">
              {{wheelOfLast.text}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item>
                <v-list-item-content>
                  {{wheelOfLast.description}}
                  <v-list-item-subtitle align="right" justify="right">- {{displayTruncateEmail(wheelOfLast.user.email)}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
import { mapState, mapActions } from 'vuex';
import VueWinwheel from '@/components/common/VueWinWheel';
import WheelOfLastSkeleton from './skeletons/WheelOfLast';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _find from 'lodash/find';

export default {
  name: 'WheelOfLast',
  async mounted () {
    try {
      this.loading = true;
      await this.setUserAsync();
      await this.setWheelOfLastsAsync();
      this.getCurrentUserWheelOfLast();
    } finally {
      this.loading = false;
    }
  },
  components: {
    WheelOfLastSkeleton,
    VueWinwheel
  },
  data: function () {
    return {
      currentUser: {},
      currentUserWheelOfLast: {},
      valid: true,
      text: '',
      description: '',
      canSpin: false,
      dialog: false,
      loading: true,
      titleRules: [
        v => !!v || 'Title is required',
        v => v.length <= 16 || 'Title must be less than 16 characters',
      ],
      descriptionRules: [
        v => !!v || 'Description is required',
        v => v.length <= 800 || 'Description must be less than 500 characters',
      ]
    };
  },
  computed: {
    ...mapState('league', ['wheelOfLasts', 'season'])
  },
  methods: {
    ...mapActions('league', [
      'setWheelOfLastsAsync',
      'createWheelOfLastAsync',
      'updateWheelOfLastAsync'
    ]),
    async setUserAsync () {
      let userInfo = await Auth.currentUserInfo();
      this.currentUser = { id: userInfo.username, email: userInfo.attributes.email };
      this.canSpin = userInfo.username == '049566c9-78e2-4593-93d7-c04f1a668d77'; //TODO: Create Cognito Group so this permission over hard coded user id
    },
    getCurrentUserWheelOfLast () {
      let me = this;
      let result = _find(this.wheelOfLasts, (item) => {
        return item.user.id == me.currentUser.id;
      });
      if (!_isEmpty(result)) {
        this.text = result.text;
        this.description = result.description;
        this.currentUserWheelOfLast = result;
      }
    },
    isEmpty (items) {
      return _isEmpty(items);
    },
    displayTruncateEmail (email) {
      return _split(email, '@')[0];
    },
    async setSpinSelected (item) {
      await this.updateWheelOfLastAsync(item);
      if (this.currentUserWheelOfLast.user.id == item.user.id)
        this.currentUserWheelOfLast.selected = true;
    },
    async submitWheelOfLast () {
      if (this.$refs.form.validate()) {
        const currentTime = new Date().toUTCString();
        let form;
        if (_isEmpty(this.currentUserWheelOfLast['id'])) {
          form = {
            leagueId: this.season.leagueId,
            text: this.text,
            description: this.description,
            user: { id: this.currentUser.id, email: this.currentUser.email },
            selected: false,
            createdAt: currentTime,
            updatedAt: currentTime
          };
          await this.createWheelOfLastAsync(form);
        }
        else {
          form = {
            id: this.currentUserWheelOfLast['id'],
            leagueId: this.currentUserWheelOfLast['leagueId'],
            text: this.text,
            description: this.description,
            user: this.currentUserWheelOfLast['user'],
            selected: this.currentUserWheelOfLast['selected'],
            createdAt: this.currentUserWheelOfLast['createdAt'],
            updatedAt: currentTime
          };
          await this.updateWheelOfLastAsync(form);
          this.currentUserWheelOfLast = form;
        }
        this.dialog = false;
      }
    }
  }
};
</script>

<style>
.text-icon-margin {
  margin: 15px !important;
}
.text-selected {
  color: #2196f3;
}
</style>
