<template>
  <v-container fill fluid>
    <DeadYearsSkeleton v-if="loading" />
    <v-card v-if="!loading">
      <v-card-title>
        <v-list-item class="grow">
          <v-list-item-content>
            <v-list-item-title v-if="owner.metadata.team_name">
              {{ owner.metadata.team_name }}
            </v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
            <v-list-item-subtitle>
              <ListItemAvatar :owner="owner" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" :headers="headers" :items="players" :footer-props="{
          'items-per-page-text': 'Players per page',
          'items-per-page-options': [5, 10, -1],
        }" item-key="full_name" dense dark class="elevation-1">
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import DeadYearsSkeleton from './skeletons/DeadYears';
import ListItemAvatar from './list-items/Avatar';
import _replace from 'lodash/replace';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import _chunk from 'lodash/chunk';
import _find from 'lodash/find';
import _uniqBy from 'lodash/uniqBy';

export default {
  name: 'DeadYears',
  async mounted () {
    try {
      this.loading = true;
      await this.getDeadYearPlayersAsync(this.$route.params.owner_id);
    } finally {
      this.loading = false;
    }
  },
  components: {
    DeadYearsSkeleton,
    ListItemAvatar
  },
  data: function () {
    return {
      players: [],
      owner: {},
      search: '',
      sortBy: 'full_name',
      sortDesc: false,
      loading: true,
      headers: [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Position', value: 'position' },
        { text: 'Team', value: 'team' },
        { text: 'Nick Names', value: 'nick_name' },
        { text: 'YOE', value: 'years_exp' },
        { text: 'Age', value: 'age' },
        { text: 'Player Status', value: 'status' },
      ],
    };
  },
  computed: {
    ...mapGetters('league', ['getPlayer', 'getDropTransactions']),
    ...mapState('league', ['owners', 'rosters']),
  },
  methods: {
    ...mapActions('league', ['setPlayerAsync', 'setOwners', 'setRosters']),
    async setStateAsync () {
      let setOwnersTask = this.setOwners();
      let setRostersTask = this.setRosters();
      await setOwnersTask;
      await setRostersTask;
    },
    async getPlayerAsync (playerId) {
      let player = this.getPlayer(playerId);
      if (!player) {
        await this.setPlayerAsync(playerId);
        player = this.getPlayer(playerId);
      }

      return player;
    },
    async getDeadYearPlayersAsync (owner_id) {
      let me = this;
      await me.setStateAsync();
      let owner = _find(me.owners, (item) => {
        return item.user_id == owner_id;
      });
      let roster = _find(me.rosters, (item) => {
        return item.owner_id == owner_id;
      });
      let dropRosterPlayers = me.getDropTransactions[roster.roster_id];
      let results = [];

      let playerIdChunks = _chunk(dropRosterPlayers, 3);
      for (let playerIdChunk of playerIdChunks) {
        let playersTasks = [];
        for (let playerId of playerIdChunk) {
          try {
            playersTasks.push(
              me.getPlayerAsync('/integration/sleeper/nfl/players/' + playerId)
            );
          } catch {
            //eat
          }
        }
        let players = await Promise.all(playersTasks);
        players.forEach((player) => {
          player['nick_name'] = roster.metadata['p_nick_' + player.player_id];
          if (
            player['nick_name'] &&
            !_includes(_lowerCase(player['nick_name']), 'taxi', 0)
          ) {
            if (this.getNumberFromString(player['nick_name']) > 0) {
              results.push(player);
            }
          }
        });
      }

      owner.metadata.team_name = owner.metadata.team_name ?? 'N/A';
      owner['avatar_thumb'] = 'https://sleepercdn.com/avatars/thumbs/' + owner.avatar;
      owner['avatar_size'] = 22;
      this.owner = owner;
      this.players = _uniqBy(results, 'player_id');
    },
    getNumberFromString (item) {
      let itemNumber = _replace(item, /\D/g, '');
      let result = 0;
      try {
        if (itemNumber) result = parseInt(itemNumber[0]);
      } catch {
        //eat
      }

      return result;
    },
  },
};
</script>

<style></style>
