<template>
  <v-container fill fluid>
    <WhatIfSkeleton v-if="loading" />
    <v-card v-if="!loading">
      <v-card-title>
        <v-list-item class="grow">
          <v-list-item-content>
            <v-list-item-title v-if="owner.metadata.team_name">
              {{ owner.metadata.team_name }}
            </v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
            <v-list-item-subtitle>
              <ListItemAvatar :owner="owner" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              Total CY:
              <v-chip :color="getColorTotalCy(totalCy)" dark>
                {{ totalCy }} / 50
              </v-chip>
              <v-btn color="primary" dark class="mb-2" @click="loadTableAsync">
                RESET
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-data-table @current-items="setTotalCy" :hide-default-footer="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" :headers="headers" :items="players" :footer-props="{
          'items-per-page-text': 'Players per page',
          'items-per-page-options': [-1],
        }" item-key="full_name" dense dark class="elevation-1">
        <template v-slot:item.nick_name="props">
          <v-chip :color="getColorNickNames(props.item.nick_name)" dark class="row-pointer">
            <v-edit-dialog :return-value.sync="props.item.nick_name" large persistent @save="saveNickNames" @cancel="cancelNickNames" @open="openNickNames" @close="closeNickNames">
              {{ props.item.nick_name }}
              <template v-slot:input>
                <v-text-field v-model="props.item.nick_name" :rules="[max5contractyears]" label="Edit" single-line counter></v-text-field>
              </template>
            </v-edit-dialog>
          </v-chip>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import WhatIfSkeleton from './skeletons/WhatIf';
import ListItemAvatar from './list-items/Avatar';
import _replace from 'lodash/replace';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import _chunk from 'lodash/chunk';
import _find from 'lodash/find';
import _toString from 'lodash/toString';

export default {
  name: 'WhatIf',
  async mounted () {
    this.loadTableAsync();
  },
  components: {
    WhatIfSkeleton,
    ListItemAvatar
  },
  data: function () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      totalCy: 0,
      max5contractyears: v => v.replace(/\D/g, '') <= 6 && v.replace(/\D/g, '') > 0 || '0 > CY <= 6',
      players: [],
      owner: {},
      search: '',
      sortBy: 'nick_name',
      sortDesc: false,
      loading: true,
      headers: [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Position', value: 'position' },
        { text: 'Team', value: 'team' },
        { text: 'Nick Names', value: 'nick_name' },
        { text: 'IR', value: 'is_roster_ir' },
        { text: 'YOE', value: 'years_exp' },
        { text: 'Age', value: 'age' },
        { text: 'Player Status', value: 'status' },
      ],
    };
  },
  computed: {
    ...mapGetters('league', ['getPlayer']),
    ...mapState('league', ['owners', 'rosters']),
  },
  methods: {
    ...mapActions('league', ['setPlayerAsync', 'setOwners', 'setRosters']),
    async loadTableAsync () {
      try {
        this.loading = true;
        await this.getRosterPlayersAsync(this.$route.params.owner_id);
      } finally {
        this.loading = false;
      }
    },
    async setStateAsync () {
      let setOwnersTask = this.setOwners();
      let setRostersTask = this.setRosters();
      await setOwnersTask;
      await setRostersTask;
    },
    async getPlayerAsync (playerId) {
      let player = this.getPlayer(playerId);
      if (!player) {
        await this.setPlayerAsync(playerId);
        player = this.getPlayer(playerId);
      }

      return player;
    },
    async getRosterPlayersAsync (owner_id) {
      let me = this;
      await me.setStateAsync();
      let owner = _find(me.owners, (item) => {
        return item.user_id == owner_id;
      });
      let roster = _find(me.rosters, (item) => {
        return item.owner_id == owner_id;
      });
      let results = [];

      let playerIdChunks = _chunk(roster.players, 20);
      for (let playerIdChunk of playerIdChunks) {
        let playersTasks = [];
        for (let playerId of playerIdChunk) {
          try {
            playersTasks.push(
              me.getPlayerAsync('/integration/sleeper/nfl/players/' + playerId)
            );
          } catch {
            //eat
          }
        }
        let players = await Promise.all(playersTasks);
        players.forEach((player) => {
          player['nick_name'] = roster.metadata['p_nick_' + player.player_id];
          player['is_roster_ir'] = _includes(
            roster.reserve,
            _toString(player.player_id)
          );
          results.push(player);
        });
      }

      owner.metadata.team_name = owner.metadata.team_name ?? 'N/A';
      owner['avatar_thumb'] = 'https://sleepercdn.com/avatars/thumbs/' + owner.avatar;
      owner['avatar_size'] = 22;
      this.owner = owner;
      this.players = results;
    },
    saveNickNames () {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancelNickNames () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    openNickNames () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'CY Edit Opened'
    },
    closeNickNames () {
      console.log('CY Edit Closed')
    },
    getColorNickNames (nickName) {
      if (!nickName)
        return 'red';
      if (_includes(_lowerCase(nickName), 'taxi', 0))
        return 'blue';

      let number = this.getNumberFromString(nickName);
      if (number > 6 || number < 1) return 'red'
      else return 'green'
    },
    getColorTotalCy (item) {
      if (!item)
        return 'red';
      if (!item || item > 50 || item < 1) return 'red'
      else return 'green'
    },
    setTotalCy (items) {
      let total = 0;
      items.forEach((item) => {
        let number = this.getNumberFromString(item.nick_name);
        if (number)
          total += number;
      });
      this.totalCy = total;
    },
    getNumberFromString (item) {
      let result = 0;
      if (_includes(_lowerCase(item), 'cy', 0)) {
        let itemNumber = _replace(item, /\D/g, '');

        try {
          if (itemNumber) result = parseInt(itemNumber);
        } catch {
          //eat
        }
      }
      return result;
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
