<template>
  <v-card flat>
    <v-card-text>
      <ul>
        <li>
          Six teams will make the playoffs
          <ul>
            <li>The five best record teams will be the top five seeds</li>
            <li>
              The sixth seed will be determined by the team with the most
              "Points For" scored, ignoring record
            </li>
            <li>
              The two best teams get a first round bye and the top two seeds
            </li>
          </ul>
        </li>
        <li>
          Tie breaker
          <ol type="1">
            <li>Total points scored</li>
            <li>Head to Head record</li>
          </ol>
        </li>
      </ul>
      <v-timeline dark>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline">
              Week 14
            </v-card-title>
            <v-card-text>
              <ul>
                <li>#3 vs #6</li>
                <li>#4 vs #5</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline justify-end">
              Week 15
            </v-card-title>
            <v-card-text class="theList">
              <ul>
                <li>Winner of the #3 vs #6 will play the #2 seed</li>
                <li>winner of the #4 vs #5 will play the #1 seed</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item small dense>
          <v-card class="elevation-2">
            <v-card-title class="headline">
              Week 16
            </v-card-title>
            <v-card-text>
              <ul>
                <li>Week 15 winners will play in the championship</li>
                <li>Week 15 losers will play for 3rd place</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Playoffs',
  data () {
    return {
      header: 'Playoffs',
    };
  },
};
</script>

<style scoped>
.theList {
  text-align: right;
  list-style-position: inside;
}
</style>
