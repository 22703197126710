<template>
  <v-container fill fluid>
    <PlayersSkeleton v-if="loading" />
    <v-card v-if="!loading">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" :headers="headers" :items="rfaPlayers" :single-select="singleSelect" :footer-props="{
          'items-per-page-text': 'Players per page',
          'items-per-page-options': [5, 10, 20, 50, -1],
        }" item-key="full_name" show-select dense dark class="elevation-1">
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import rfaMixin from '../../mixins/rfaMixin.js';
import PlayersSkeleton from './skeletons/Players';

export default {
  name: 'RfaPlayers',
  mixins: [rfaMixin],
  async mounted () {
    try {
      this.loading = true;
      await this.setOwnerYearRosterStateAsync(null);
      await this.getRfaPlayersAsync();
    } finally {
      this.loading = false;
    }
  },
  components: {
    PlayersSkeleton,
  },
  data() {
    return {
      loading: true,
      search: '',
      sortBy: 'full_name',
      sortDesc: false,
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Position', value: 'position' },
        { text: 'Team', value: 'team' },
        { text: 'YOE', value: 'years_exp' },
        { text: 'Age', value: 'age' },
        { text: 'Player Status', value: 'status' },
        { text: 'Owner', value: 'owner_name' },
        { text: 'FF Team', value: 'owner_team_name' },
      ],
    };
  },
};
</script>

<style></style>
