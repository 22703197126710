<template>
  <v-container fill fluid>
    <RosterDetailsSkeleton v-if="loading" />
    <v-card v-if="!loading">
      <v-card-title>
        <v-list-item class="grow">
          <v-list-item-content>
            <v-list-item-title v-if="owner.metadata.team_name">
              {{ owner.metadata.team_name }}
            </v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
            <v-list-item-subtitle>
              <ListItemAvatar :owner="owner" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" :headers="getHeaders()" :items="players" :footer-props="{
          'items-per-page-text': 'Players per page',
          'items-per-page-options': [5, 10, -1],
        }" item-key="full_name" dense dark class="elevation-1">
        <template v-slot:item.previous_nick_name="{ value, item }">
          <v-chip v-if="!item.hasGoodCY" :color="getColor(item)">
            {{ value }}
          </v-chip>
          <div v-if="item.hasGoodCY">{{ value }}</div>
        </template>
        <template v-slot:item.nick_name="{ value, item }">
          <v-chip v-if="!item.hasGoodCY" :color="getColor(item)">
            {{ value }}
          </v-chip>
          <div v-if="item.hasGoodCY">{{ value }}</div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import rfaMixin from '../../mixins/rfaMixin.js'
import { mapState, mapActions, mapGetters } from 'vuex';
import RosterDetailsSkeleton from './skeletons/RosterDetails';
import ListItemAvatar from './list-items/Avatar';
import _includes from 'lodash/includes';
import _chunk from 'lodash/chunk';
import _find from 'lodash/find';
import _toString from 'lodash/toString';
import _parseInt from 'lodash/parseInt';
import _lowerCase from 'lodash/lowerCase';
import _replace from 'lodash/replace';

export default {
  name: 'RosterDetails',
  mixins: [rfaMixin],
  async mounted () {
    try {
      this.loading = true;
      await this.getRosterPlayersAsync(this.$route.params.owner_id);
    } finally {
      this.loading = false;
    }
  },
  components: {
    RosterDetailsSkeleton,
    ListItemAvatar
  },
  data: function () {
    return {
      players: [],
      owner: {},
      search: '',
      sortBy: 'full_name',
      sortDesc: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('league', ['getPlayer']),
    ...mapState('league', ['owners', 'rosters', 'yearRosters', 'seasons', 'season']),
  },
  methods: {
    ...mapActions('league', ['setPlayerAsync', 'setOwners', 'setRosters']),
    async setStateAsync () {
      let setOwnersTask = this.setOwners();
      let setRostersTask = this.setRosters();
      let setOwnerYearRoster = this.setOwnerYearRosterStateAsync(this.findPreviousSeasonLeagueId());
      await setOwnersTask;
      await setRostersTask;
      await setOwnerYearRoster;
    },
    async getPlayerAsync (playerId) {
      let player = this.getPlayer(playerId);
      if (!player) {
        await this.setPlayerAsync(playerId);
        player = this.getPlayer(playerId);
      }

      return player;
    },
    async getRosterPlayersAsync (owner_id) {
      let me = this;
      await me.setStateAsync();
      let owner = _find(me.owners, (item) => {
        return item.user_id == owner_id;
      });
      let roster = _find(me.rosters, (item) => {
        return item.owner_id == owner_id;
      });
      let yearRoster = _find(me.yearRosters, (item) => {
        return item.owner_id == owner_id;
      });
      let results = [];

      let playerIdChunks = _chunk(roster.players, 20);
      for (let playerIdChunk of playerIdChunks) {
        let playersTasks = [];
        for (let playerId of playerIdChunk) {
          try {
            playersTasks.push(
              me.getPlayerAsync('/integration/sleeper/nfl/players/' + playerId)
            );
          } catch {
            //eat
          }
        }
        let players = await Promise.all(playersTasks);
        players.forEach((player) => {
          player['previous_nick_name'] = yearRoster.metadata['p_nick_' + player.player_id];
          player['nick_name'] = roster.metadata['p_nick_' + player.player_id];
          player['hasGoodCY'] = me.checkPlayerCy(player['nick_name'], player['previous_nick_name']);
          player['is_roster_ir'] = _includes(
            roster.reserve,
            _toString(player.player_id)
          );
          results.push(player);
        });
      }

      owner.metadata.team_name = owner.metadata.team_name ?? 'N/A';
      owner['avatar_thumb'] = 'https://sleepercdn.com/avatars/thumbs/' + owner.avatar;
      owner['avatar_size'] = 22;
      this.owner = owner;
      this.players = results;
    },
    checkPlayerCy(currentPlayerNN, yearPlayerNN){
      if(!_includes(_lowerCase(currentPlayerNN), 'taxi', 0) && yearPlayerNN)  {
        if((_includes(_lowerCase(yearPlayerNN), 'taxi', 0) && _includes(_lowerCase(currentPlayerNN), 'cy', 0)) || (_lowerCase(yearPlayerNN) === '1 cy' && (_lowerCase(currentPlayerNN) === 'rfa' || _includes(_lowerCase(currentPlayerNN), 'cy', 0))))
            return true;
        if ((!_includes(_lowerCase(currentPlayerNN), 'cy', 0) && !_includes(_lowerCase(yearPlayerNN), 'cy', 0)) || (_lowerCase(yearPlayerNN) === '1 cy' && (_lowerCase(currentPlayerNN) !== 'rfa' || !_includes(_lowerCase(currentPlayerNN), 'cy', 0)))) {
          return false;
        }
        else{
          let currentPlayerCY = this.getNumberFromString(currentPlayerNN);
          let yearPlayerCY = this.getNumberFromString(yearPlayerNN) - 1;
          if(currentPlayerCY !== yearPlayerCY)
            return false;
        }
      }
      
      return true;
    },
    getNumberFromString (item) {
      let itemNumber = _replace(item, /\D/g, '');
      let result = 0;
      try {
        if (itemNumber) result = parseInt(itemNumber[0]);
      } catch {
        //eat
      }

      return result;
    },
    findPreviousSeasonLeagueId() {
      let prevYear = _parseInt(this.season.year) - 1;
      let result = _find(this.seasons, {'year': prevYear.toString()});
      return result.leagueId;
    },
    getColor (item) {
      return item.hasGoodCY ? 'green' : 'red';
    },
    getHeaders(){
      let previousSeasonYear = _parseInt(this.season.year) - 1;
      return [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Position', value: 'position' },
        { text: 'Team', value: 'team' },
        { text: previousSeasonYear + ' Nick Names', value: 'previous_nick_name' },
        { text: 'Nick Names', value: 'nick_name' },
        { text: 'IR', value: 'is_roster_ir' },
        { text: 'YOE', value: 'years_exp' },
        { text: 'Age', value: 'age' },
        { text: 'Player Status', value: 'status' },
      ];
    }
  },
};
</script>

<style></style>
